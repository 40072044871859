import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Upsell from "../components/upsell"

const IndexPage = () => (
  <Layout>
    <SEO title="Inicio" />
    <h1>Desarrollo Web</h1>
    <Upsell />
  </Layout>
)

export default IndexPage
