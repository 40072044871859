import React from "react"
import PropTypes from "prop-types"

const UpsellPitch = ({ children }) => (
  <div>{children}</div>
)

UpsellPitch.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UpsellPitch
