import React from "react"
import PropTypes from "prop-types"

const UpsellNeed = ({ children }) => (
  <p className="upsell-need">{children}</p>
)

UpsellNeed.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UpsellNeed
