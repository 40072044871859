import React from "react"
import PropTypes from "prop-types"

const UpsellHeader = ({ children }) => (
  <h2>{children}</h2>
)

UpsellHeader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UpsellHeader
