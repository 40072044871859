import React from "react"
import PropTypes from "prop-types"

const UpsellSection = ({ children }) => (
  <div className="upsell-section">
    {children}
  </div>
)

// < div >
// <p className="upsell-do-u-want">{douwant}</p>
// <p>{tlahtollican}</p>
//   </div >

UpsellSection.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UpsellSection
