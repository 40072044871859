import React from "react"

import UpsellSection from "./UpsellSection.jsx"
import UpsellHeader from "./UpsellHeader.jsx"
import UpsellPitch from "./UpsellPitch.jsx"
import UpsellNeed from "./UpsellNeed.jsx"

const Upsell = () => (
  <div>
    <UpsellSection>
      <UpsellHeader>...para ti</UpsellHeader>
      <UpsellPitch>
        <UpsellNeed>
          ¿Quieres un blog para escribir sobre aquello que te apasiona?
        </UpsellNeed>

        Tlahtolli te ayuda a hacerlo, con las mejores tecnologías
        de administración de contenido para tener un blog rápido, con excelente SEO,
        y en el que publicar nuevo contenido es una tarea sencilla.

      </UpsellPitch>

    </UpsellSection>

    <UpsellSection>

      <UpsellHeader>...para tu negocio</UpsellHeader>

      <UpsellPitch>
        <UpsellNeed>
          ¿Necesitas software especializado pero tu presupuesto es limitado?
        </UpsellNeed>
        <p>
          Tlahtolli te ayuda a hacerlo, con las mejores tecnologías
          de administración de contenido para tener un blog rápido, con excelente SEO,
          y en el que publicar nuevo contenido es una tarea sencilla.
        </p>
        <ul>
          <li>Administrar las relaciones con clientes</li>
          <li>Administrar documentos</li>
          <li>Realizar exámenes o dar seguimiento a una capacitación</li>
          <li>Llevar control de tus productos y catálogo</li>
          <li>Automatizar tu estrategia de <i>marketing</i> en línea</li>
          <li>Realizar encuestas y cuestionarios</li>
        </ul>


      </UpsellPitch>

      <UpsellPitch>
        <UpsellNeed>
          ¿Necesitas software que se ajuste a tus propias especificaciones?
        </UpsellNeed>
        <p>Tlahtolli te acompaña seleccionando y desplegando software Open Source para:</p>
        <ul>
          <li>Aplicaciones Web</li>
          <li>APIs Web</li>
          <li>Mantenimiento y mejora de aplicaciones existentes</li>
        </ul>
      </UpsellPitch>

    </UpsellSection>
  </div>
)

export default Upsell